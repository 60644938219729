<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">

      </template>
    </side-bar>
    <div class="main-panel">

      <div class="area-box">
        <div class="NotifyArea"> SELECT AREA</div>
      </div>
      <!-- <button class="area-1" @click="joinArea">
        <div class=" areaname">
          ALL AREA
        </div>
        
      </button> -->
      <button class="area-1" @click="joinArea">
        <div class=" areaname">
          BTCE
        </div>
        
      </button>
      <div>
          <ul class="feature-list">
  <li class="feature-item">
    <span class="feature-item-number">1</span>
    Bảng điều khiển:
    <h3> thông số hệ thống hiện tại và quá khứ</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">2</span>
    Danh sách người dùng:
    <h3> tra cứu danh sách người dùng và chỉnh sửa thông tin</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">3</span>
    Xác minh:
    <h3> lịch sử nộp KYC và xác minh (Duyệt/Từ chối)</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">4</span>
    Bẻ cầu:
    <h3> Vào lệnh acc User - Thay đổi kết quả - Cài đặt tự động bẻ cầu</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">5</span>
    Nạp tiền:
    <h3> danh sách nạp tiền</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">6</span>
    Rút tiền:
    <h3> danh sách rút tiền</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">7</span>
    Hoa hồng:
    <h3> kiểm tra hoa hồng và volume 7 tầng</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">8</span>
    Cài đặt:
    <h3> Tạo và phân quyền, thay đổi thông tin tài khoản admin, tạo thông báo đến user</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">9</span>
    <h3> Lịch sử bơm tiền marketing</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">10</span>
    Marketing:
    <h3>Lịch sử giao dịch: Lịch sử giao dịch của user</h3>
  </li>
  <li class="feature-item">
    <span class="feature-item-number">11</span>
    Tạo hoa hồng:
    <h3> Tạo hoa hồng </h3>
  </li>
</ul>
      
    </div>
    </div>
   <div>
    
   </div>
  </div>
</template>
<style>
  .feature-list {
    width: 380px;
    margin: 20px auto;
    padding: 0;
    list-style-type: none;
  }

  .feature-item {
    position: relative;
    padding: 15px 20px 15px 60px;
    margin-bottom: 15px;
    background: linear-gradient(135deg, #3f3f3f, #353d49); /* Gradient nền */
    border-radius: 8px;
    color: #ffffff;
    font-size: 1.1rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Hiệu ứng bóng đổ */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }

  /* Hiệu ứng khi hover */
  .feature-item:hover {
    transform: translateY(-5px); /* Di chuyển nhẹ lên trên */
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3); /* Bóng đổ sâu hơn */
  }

  /* Vòng tròn số thứ tự */
  .feature-item-number {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    background-color: #ffffff;
    color: #6a11cb;
    font-weight: bold;
    font-size: 1.2rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  }

  /* Tiêu đề */
  .feature-item h3 {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
  }
</style>



<style lang="scss">
.area-box {
  width: 100%;
  height: 80px;
  background: #2dce89;
  border-radius: 10px;
  position: relative;
}

.area-1 {
  margin-top: 20px;
  width: 54%;
  background: linear-gradient(130deg, rgb(255, 255, 255) 24%, rgb(33, 39, 48)5%);
  position: relative;
  border-radius: 10px;
  height: 80px;
}

.area-1:hover {
  background: linear-gradient(130deg, rgb(255, 255, 255) 24%, rgb(33, 39, 48)5%);
  box-shadow: 0 3px 10px rgba(0, 255, 60, 0.2);
}

.areaname {
  text-align: center;
  padding: 15px;
  font-size: 30px;
  color: #fff;
  background: linear-gradient(90deg, rgba(255,0,0,1) 1%, rgba(121,22,9,1) 35%, rgba(255,0,31,1) 100%);
}

.NotifyArea {
  text-align: center;
  padding-top: 5px;
  font-size: 50px;
}
</style>
<script>

export default {
  components: {

  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    joinArea() {
      this.$router.push({ name: 'dashboard' });
    }
  }
};
</script>
